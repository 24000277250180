<template>
  <div class="classStudyNumDetails">
    <template v-if="$route.name == 'classStudyNumDetails'">
      <div class="search_sList">
        <div class="top_opera">
          <el-form
            :inline="true"
            :model="formInline"
            class="demo-form-inline"
            label-width="80px"
          >
            <el-form-item label="学校">
              <el-select
                v-model="formInline.schoolId"
                placeholder="请选择学校"
                @change="changeTen"
                filterable
                clearable
              >
                <el-option
                  v-for="item in allSchoolData"
                  :key="item.tenantId"
                  :label="item.tenantName"
                  :value="item.tenantId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="姓名">
              <el-input
                v-model="formInline.name"
                placeholder="姓名"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="身份证号">
              <el-input
                v-model="formInline.cardId"
                placeholder="身份证号"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="招生批次:" prop="admissionBatch">
              <el-select
                v-model="formInline.admissionBatch"
                placeholder="请选择"
                @focus="getGoSchoolData"
              >
                <el-option
                  v-for="item in schoolBatchData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="招生层次">
              <el-select v-model="formInline.eduCategory" placeholder="层次">
                <el-option
                  v-for="(item, key, index) in $common.getEduCategory"
                  :key="index"
                  :label="item"
                  :value="key"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="招生专业">
              <el-select
                v-model="formInline.majorId"
                placeholder="请选择"
                @focus="getMajorDataEvent"
                filterable
                clearable
              >
                <el-option
                  v-for="item in majorNameData"
                  :key="item.majorId"
                  :label="item.majorName"
                  :value="item.majorId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="教学点">
              <el-select
                v-model="formInline.stationId"
                placeholder="教学点"
                filterable
                clearable
                @focus="getTheDropDownBoxData"
              >
                <el-option
                  v-for="item in correspondenceStation"
                  :key="item.stationsId"
                  :label="item.stationsName"
                  :value="item.stationsId"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="btn_area">
            <el-button class="mainBtn" @click="onSubmit" plain v-preventReClick
              >搜索</el-button
            >
            <el-button type="info" @click="resetEvent" plain>重置</el-button>
          </div>
        </div>
      </div>
      <div class="table_sList">
        <el-table
          :data="tableData"
          stripe
          border
          style="width: 100%"
          align="center"
        >
          <el-table-column prop="schoolName" label="学校" align="center">
            <template slot-scope="scope">
              <el-tooltip
                :content="scope.row.schoolName"
                placement="top"
                effect="light"
                popper-class="pubToop"
              >
                <div class="ellipsis">{{ scope.row.schoolName || "/" }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="姓名" align="center">
            <template slot-scope="scope">
              <el-tooltip
                :content="scope.row.name"
                placement="top"
                effect="light"
                popper-class="pubToop"
              >
                <div class="ellipsis">{{ scope.row.name || "/" }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="loginNum" label="助学账号" align="center">
            <template slot-scope="scope">
              <el-tooltip
                :content="scope.row.loginNum"
                placement="top"
                effect="light"
                popper-class="pubToop"
              >
                <div class="ellipsis">{{ scope.row.loginNum || "/" }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="cardId" label="身份证号" align="center">
            <template slot-scope="scope">
              <el-tooltip
                :content="scope.row.cardId"
                placement="top"
                effect="light"
                popper-class="pubToop"
              >
                <div class="ellipsis">{{ scope.row.cardId || "/" }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="stationName" label="教学点" align="center">
            <template slot-scope="scope">
              <el-tooltip
                :content="scope.row.stationName"
                placement="top"
                effect="light"
                popper-class="pubToop"
              >
                <div class="ellipsis">{{ scope.row.stationName || "/" }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="admissionBatch"
            label="招生批次"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="eduCategoryName"
            label="招生层次"
            align="center"
          >
          </el-table-column>
          <el-table-column label="招生专业" align="center" width="80">
            <template slot-scope="scope">
              <el-tooltip
                :content="scope.row.majorName"
                placement="top"
                effect="light"
                popper-class="pubToop"
              >
                <div class="ellipsis">{{ scope.row.majorName || "/" }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="createTime"
            label="添加时间"
            align="center"
            width="140"
          >
            <template slot-scope="scope">
              <span>{{
                timestampReturnDate(scope.row.createTime, "datetime") || "/"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="来源途径" align="center" width="80">
            <template slot-scope="scope">
              <el-tooltip
                :content="scope.row.source"
                placement="top"
                effect="light"
                popper-class="pubToop"
              >
                <div class="ellipsis">{{ scope.row.source || "/" }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="accountStatusName"
            label="账号状态"
            align="center"
          >
          </el-table-column>
          <el-table-column label="备注" align="center" width="80">
            <template slot-scope="scope">
              <el-tooltip
                :content="scope.row.remarks"
                placement="top"
                effect="light"
                popper-class="pubToop"
              >
                <div class="ellipsis">{{ scope.row.remarks || "/" }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="170"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="jumpDetails(scope.row)"
                >详情</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="operaEvent('reset', scope.row)"
                v-if="scope.row.accountStatus == 1"
                >重置密码</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="operaEvent('close', scope.row)"
                v-if="scope.row.accountStatus == 1"
                >关闭账号</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <Pagination @changePage="changePage" :childMsg="childMsg"></Pagination>
      </div>
      <Keydown @keydownEvent='handleKeydown'></Keydown>
      <dialogDiy
        :isShow="isShowStudyOpera"
        :dialogDiyData="dialogStudyDiyData"
        @dialogEvent="isShowStudyOpera = false"
      >
        <div slot="dialogContent" class="dia_opera">
          <img class="errIcon" src="../../../assets/img/warn.png" alt="" />
          <p>{{ title }}</p>
          <div class="btn_area">
            <el-button @click="isShowStudyOpera = false">关闭</el-button>
            <el-button class="mainBtn" @click="doEvent">确定</el-button>
          </div>
        </div>
      </dialogDiy>
    </template>
    <template v-else>
      <router-view></router-view>
    </template>
  </div>
</template>

<script>
import way from "../../../api/encapsulation";
import Pagination from "../../Pagination";
import dialogDiy from "../../commonModule/dialogDiy";
export default {
  name: "classStudyNumDetails",
  components: {
    Pagination,
    dialogDiy
  },
  data() {
    return {
      formInline: {
        artsSciences: null,
        accountStatus: null,
        admissionBatch: null,
        cardId: null,
        disputeFlag: null,
        eduCategory: null,
        majorId: null,
        name: null,
        page: 1,
        pageSize: 10,
        schoolId: null,
        stationId: null,
        subjectCode: null,
        aidClassId: this.$route.query.aidClassId
      },
      allSchoolData: [],
      schoolBatchData: [],
      majorNameData: [],
      correspondenceStation: [],
      tableData: [],
      childMsg: {
        current: 1,
        size: 10,
        total: 1
      },
      title: null,
      isShowStudyOpera: false,
      dialogStudyDiyData: {
        title: null,
        sizeStyle: { width: "400px", height: "234px" }
      },
      rowData: null,
      rowType: null
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal.name == "classStudyNumDetails") {
          this.formInline.subjectCode = newVal.query.subjectCode;
          this.formInline.eduCategory = String(newVal.query.eduCategory);
          this.formInline.artsSciences = newVal.query.artsSciences;
          this.getTenantDown();
          this.queryClassStudent();
        }
      }
    }
  },
  methods: {
    timestampReturnDate: way.timestampReturnDate,
    handleKeydown(event) {
      if (!this.isShowStudyOpera) {
        this.onSubmit()
      }
    },
    // 搜索
    onSubmit() {
      this.formInline.page = 1;
      this.formInline.pageSize = 10;
      this.queryClassStudent();
    },
    // 重置
    resetEvent() {
      this.formInline.accountStatus = null;
      this.formInline.admissionBatch = null;
      this.formInline.cardId = null;
      this.formInline.disputeFlag = null;
      this.formInline.eduCategory = null;
      this.formInline.majorId = null;
      this.formInline.name = null;
      this.formInline.page = 1;
      this.formInline.pageSize = 10;
      this.formInline.schoolId = null;
      this.formInline.stationId = null;
    },
    changeTen() {
      this.formInline.admissionBatch = null;
      this.schoolBatchData = [];
      this.getGoSchoolData();
      this.formInline.majorId = null;
      this.majorNameData = [];
      this.formInline.stationId = null;
      this.correspondenceStation = [];
      this.getTheDropDownBoxData();
    },
    // 获取学校数据
    getTenantDown() {
      this.$request.getTenantDownData({}, res => {
        if (res.code == 0) {
          this.allSchoolData = res.data;
        }
      });
    },
    // 获取入学批次数据
    getGoSchoolData() {
      if (!this.formInline.schoolId) {
        this.Warn("先选择学校");
        return;
      }
      if (this.schoolBatchData.length != 0) return;
      let obj = {
        tenantId: this.formInline.schoolId
      };
      this.$request.aidBatchDropDown(obj, res => {
        if (res.code == 0) {
          this.schoolBatchData = res.data;
        }
      });
    },
    // 专业数据
    getMajorDataEvent() {
      if (!this.formInline.schoolId) {
        this.Warn("请选择学校");
        return;
      }
      // if (!this.formInline.admissionBatch) {
      //   this.Warn("请选择批次");
      //   return;
      // }
      // if (
      //   !(
      //     typeof this.formInline.eduCategory == "string" ||
      //     typeof this.formInline.eduCategory == "number"
      //   )
      // ) {
      //   this.Warn("请选择层次");
      //   return;
      // }
      if (this.majorNameData.length != 0) return;
      let obj = {
        tenantId: this.formInline.schoolId,
        eduCategory: this.formInline.eduCategory,
        batch: this.formInline.admissionBatch
      };
      this.$request.aidMajorDropDown(obj).then(res => {
        if (res.data.code == 0) {
          this.majorNameData = res.data.data;
        }
      });
    },
    // 获取教学点数据
    getTheDropDownBoxData() {
      if (!this.formInline.schoolId) {
        this.Warn("请选择学校");
        return;
      }
      if (this.correspondenceStation.length != 0) return;
      let obj = {
        tenantId: this.formInline.schoolId
      };
      this.$request.getTheDropDownBoxs(obj, res => {
        if (res.code == 0) {
          this.correspondenceStation = res.data;
        }
      });
    },
    // 获取班级助学生数据
    queryClassStudent() {
      this.$request.queryClassStudent(this.formInline).then(res => {
        if (res.data.code == 0) {
          let { records, current, size, total } = res.data.data;
          this.tableData = records;
          this.childMsg = {
            current,
            size,
            total
          };
        }
      });
    },
    changePage(val) {
      this.childMsg = val;
      this.formInline.page = val.current;
      this.formInline.pageSize = val.size;
      this.queryClassStudent();
    },
    showHideEvent() {
      this.isShowStudyOpera = !this.isShowStudyOpera;
    },
    operaEvent(type, row) {
      this.title =
        type == "reset" ? "确定是否要重置密码？" : "确定是否要关闭账号？";
      this.rowData = row;
      this.rowType = type;
      this.showHideEvent();
    },
    doEvent() {
      if (this.rowType == "reset") {
        this.$request
          .resetStuPass({ username: this.rowData.loginNum })
          .then(res => {
            if (res.data.code == 0) {
              this.Success(res.data.data);
              this.queryClassStudent();
              this.showHideEvent();
            }
          });
      } else {
        let obj = {
          aidId: this.rowData.id,
          tenantId: this.rowData.tenantId
        };
        this.$request.closeAccount(obj).then(res => {
          if (res.data.code == 0) {
            this.Success(res.data.msg);
            this.queryClassStudent();
            this.showHideEvent();
          }
        });
      }
    },
    jumpDetails(row) {
      this.$router.push({
        name: "studentStudyDetails",
        query: {
          tenantId: row.tenantId,
          aidStudentId: row.id,
          breadcrumb: "助学管理,班级管理,学习人数详情"
        }
      });
    }
  }
};
</script>

<style lang="less">
.classStudyNumDetails {
  height: 100%;
}
</style>
